
import React from "react";
import moment from 'moment';
import "moment/locale/es";

moment.locale('es');


const sermons = props => {

    return (
        <section id="sermones" className="portfolio">
            <div className="container">

                <div className="section-title">
                    <span>Servicios Grabados</span>
                    <h2>Servicios Grabados</h2>
                    <p>Te perdiste un servicio? Escucha la grabacion en linea y no piedas la opotunida de que Dios hable a tu vida!</p>
                </div>

                <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="150">

                    { props.videos.length > 0 ? props.videos.map(video => {
                        
                        return (
                             <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                                <img src={video.thumbnails.url} className="img-fluid" alt="" />
                                <div onClick={() => props.openVideo(video.videoId)} className="portfolio-info">
                                    <div class="events-meta"><span><i class="bx bx-calendar" aria-hidden="true"></i> {video.liveBroadcastContent === 'none' ? `Publicado ${moment(video.publishedAt, "YYYYMMDD").fromNow()}` : null}</span></div>
                                    <p>{video.title}</p>
                                <a className="details-link" title="More Details"><i className="bx bx-play-circle"></i></a>
                            </div>
                        </div>
                        );
                    }) : null
                }

                    

                </div>
            </div>
        </section>
    );
}

export default sermons;