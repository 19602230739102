import { host } from './utils'
import axios from 'axios';
const Collections = () => {
    return {

        eventos: async () => {
            const uri = host + "/eventos"
            return await fetch(uri).then((res) => {
                return res.json()
            });
        },
        teams: async () => {
            const uri = host + "/teams"
            return await fetch(uri).then((res) => {
                return res.json()
            });
        },
        videos: async () => {
            const uri = host + "/videos"
            return await fetch(uri).then((res) => {
                return res.json()
            });
        },
        videos_anniversary: async () => {
            const uri = host + "/videos-anniversary"
            return await fetch(uri).then((res) => {
                return res.json()
            });
        },
        quienesSomos: async () => {
            const uri = host + "/quienes-somos"
            return await fetch(uri).then((res) => {
                return res.json()
            });
        },
        pastores: async () => {
            const uri = host + "/nuestro-pastores-section"
            return await fetch(uri).then((res) => {
                return res.json()
            });
        },
        ministerios: async () => {
            const uri = host + "/ministerios"
            return await fetch(uri).then((res) => {
                return res.json()
            });
        },
        diezmoOfrendas: async () => {
            const uri = host + "/diezmos-ofrendas"
            return await fetch(uri).then((res) => {
                return res.json()
            });
        },
        postContactRequest: async (payload) => {
            const uri = host + "/contactanos"
            return await axios.post(uri, payload).then(res => {
                return res;
            }).catch(error => {
                return error;
            });
        }
    }

}

export default Collections();