import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap'
import React, { useRef, useEffect } from "react";
import ReactDOM from 'react-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'

// nodejs library that concatenates strings
import classnames from "classnames";


import Logo from "../assets/img/logo.svg";

const IndexNavbar = props => {
    const [navbarColor, setNavbarColor] = React.useState("navbar-base-theme");
    const [activeMenu, setActiveMenu] = React.useState("conocenos");
    const dropDownRef = useRef(null);


    useEffect(() => {
        const updateNavbarColor = () => {
            if (
                document.documentElement.scrollTop > 299 ||
                document.body.scrollTop > 299 || window.screen.width <= 990
            ) {
                setNavbarColor("navbar-dark");
            } else if (
                document.documentElement.scrollTop < 300 ||
                document.body.scrollTop < 300
            ) {
                setNavbarColor("");
            }
        };

        window.addEventListener("scroll", updateNavbarColor);
        window.addEventListener("load", updateNavbarColor)
        return function cleanup() {
            window.removeEventListener("scroll", updateNavbarColor);

        };
    });

    const updateMenu = (activeMenu) => {

        //If mobile close the drop down on click
        if (dropDownRef.current.classList.contains("show")) {
            dropDownRef.current.classList.remove("show");
        }


        setActiveMenu(activeMenu)
    }

    return (

        <Navbar className={classnames("navbar-base-theme", navbarColor)} expand="lg" color-on-scroll="300" fixed="top">
            <Container>
                <Navbar.Brand className="" href="#home">
                    <img
                        src={Logo}
                        width="115"
                        height="40"
                        className="d-inline-block align-top"
                        alt="La Mies Logo"
                    />
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" bsPrefix="" />
                <Navbar.Collapse className="justify-content-end" ref={dropDownRef} id="basic-navbar-nav">
                    <Nav className="nav-menu" activeKey={activeMenu}
                    >
                        <Nav.Item onClick={e => updateMenu("conocenos")}><AnchorLink offset='75' className={"nav-link " + (activeMenu === "conocenos" ? "active" : "")} href="#conocenos"> Conocenos</AnchorLink></Nav.Item>
                        <NavDropdown className={(activeMenu === "team" ? "active" : "")} title="Equipo" id="basic-nav-dropdown">
                            <NavDropdown.Item onClick={e => updateMenu("team")}><AnchorLink offset='50' href="#pastores"> Pastores </AnchorLink></NavDropdown.Item>
                            <NavDropdown.Item onClick={e => updateMenu("team")}><AnchorLink href="#ministros"> Ministros </AnchorLink></NavDropdown.Item>
                        </NavDropdown>

                        <Nav.Item onClick={e => updateMenu("ofrendas")}> <AnchorLink offset='72' className={"nav-link " + (activeMenu === "ofrendas" ? "active" : "")} href="#ofrendas"> Ofrendas </AnchorLink></Nav.Item>
                        <Nav.Item onClick={e => updateMenu("ministerios")}> <AnchorLink className={"nav-link " + (activeMenu === "ministerios" ? "active" : "")} href="#ministerios">Ministerios </AnchorLink></Nav.Item>
                        <Nav.Item onClick={e => updateMenu("contactos")}> <AnchorLink className={"nav-link " + (activeMenu === "contactos" ? "active" : "")} href="#contactos"> Contactos </AnchorLink></Nav.Item>
                    </Nav>

                </Navbar.Collapse >
            </Container >
        </Navbar >

    );
}

export default IndexNavbar;