
import React, { useEffect } from "react";
import moment from 'moment';
import "moment/locale/es";

import holland from "../assets/img/holland.png";
import tim from "../assets/img/tim.png";
import isaac from "../assets/img/isaac.png";
import daniel from "../assets/img/daniel.png";

moment.locale('es');

const videosMapping = [
    {
        name: "Pastora Holland",
        id: "JRZnpderjdQ",
        img: holland
    },
    {
        name: "Pastora Holland",
        id: "nOXhOoT8tP0",
        img: holland
    },
    {
        name: "Ralph Holland",
        id: "6p40zAh5jtI",
        img: holland
     
    },
    {
        name: "Tim Holland",
        id: "gaguYqTV3ok",
        img: tim
    },
    {
        name: "Tim Holland",
        id: "S1EYgYImS84",
        img: tim
    },
    {
        name: "Ralph Holland",
        id: "uSzvBfarHik",
        img: holland
    },
    {
        name: "Isaac",
        id: "XIT7SLPQ8Cc",
        img: isaac
    },
    {
        name: "Dadiel",
        id: "D9pz_FK_6lw",
        img: daniel
    }
]

const SermonsAnniversary = props => {

    useEffect(() => {
    
           const comp = document.getElementById('wrap');
           comp.scrollLeft = 200;


    })

    return (
        <section id="sermonesAniversario" className="portfolio">
            <div className="container videos-group">

                <div className="section-title">
                    <span>Conferencia Creece</span>
                    <h2>Conferencia Creece</h2>
                    <p>El señor nos habló y nos dió palabras de promesa para los proximos 10 años!</p>
                </div>

                <div id="wrap" className="row portfolio-container video-wrap" data-aos="fade-up" data-aos-delay="150">

                    { props.videos.length > 0 ? props.videos.map(video => {
                        return (
                             <div key={video.videoId} className="col-lg-4 col-md-6 portfolio-item filter-card">
                                {
                                    console.log(videosMapping.find(i => i.id === video.videoId)?.img)
                                }
                                <img src={videosMapping.find(i => i.id === video.videoId)?.img} className="img-fluid" alt="" />
                                {/* <img src={video.thumbnails.url} className="img-fluid" alt="" /> */}
                                <div onClick={() => props.openVideo(video.videoId)} className="portfolio-info">
                                    <div class="events-meta"><span><i class="bx bx-calendar" aria-hidden="true"></i> {video.liveBroadcastContent === 'none' ? `Publicado ${moment(video.publishedAt, "YYYYMMDD").fromNow()}` : null}</span></div>
                                    <p>{video.title}</p>
                                <a className="details-link" title="More Details"><i className="bx bx-play-circle"></i></a>
                            </div>
                        </div>
                        );
                    }) : null
                }

                    

                </div>
            </div>
        </section>
    );
}

export default SermonsAnniversary;