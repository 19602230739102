import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";
import Collection from "../services/collections";

const ContactForm = (props) => {
  const formDefault = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };
  const formStateDefault = {
    loading: false,
    hassError: false,
    messageSent: false,
  };

  const [form, setForm] = useState(formDefault);
  const [formState, setFormState] = useState(formStateDefault);

  const handleInputField = (field) => {
    const obj = { [field.target.name]: field.target.value };
    setForm({ ...form, ...obj });
  };

  const submitForm = (e) => {
    e.preventDefault();
    setFormState({ ...formState, ...{ loading: true } });
    Collection.postContactRequest(form).then((response) => {
      updateFormState(response);
    });
  };

  const updateFormState = (response) => {
    if (response && response.status === 200) {
      setFormState({
        ...formState,
        ...{
          loading: false,
          hassError: false,
          messageSent: true,
        },
      });
      clearForm();
    } else {
      setFormState({
        ...formState,
        ...{
          loading: false,
          hassError: true,
        },
      });
    }
  };

  const clearForm = () => {
    setForm(formDefault);
  };

  const validateEmail = (email) => {
    {
      if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email
        )
      ) {
        return true;
      }

      return false;
    }
  };

  return (
    //    <!-- ======= Contact Section ======= -->
    <section id="contactos" className="contact">
      <div className="container">
        <div className="section-title">
          <span>Contactanos</span>
          <h2>Contactanos</h2>
          <p>Contactanos y ven forma parte de nuesta congragacion. </p>
        </div>
        <div className="row" data-aos="fade-up">
          <div className="col-lg-12">
            <div className="info-box mb-4">
              <h3>Horarios de Nuestro Servicios</h3>
              <div class="row center-items">
                <div className="col-lg-3">
                  <div className="mb-2">
                    <h6>Miercoles</h6>
                    <p>7:30 PM - Servicio General</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="mb-2">
                    <h6>Viernes</h6>
                    <p>7:30 PM - Reuniones por Grupos</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="mb-2">
                    <h6>Domingo</h6>
                    <p>10:00 AM - Servicio Generales</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" data-aos="fade-up">
          <div className="col-lg-6">
            <div className="info-box mb-4">
              <i className="bx bx-map" />
              <h3>Nuestra Dirección</h3>
              <p>6515 Tezel Rd. San Antonio, Tx 78250</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="info-box  mb-4">
              <i className="bx bx-envelope" />
              <h3>Escribenos</h3>
              <p>webmaster@iglesialamies.org</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="info-box  mb-4">
              <i className="bx bx-phone-call" />
              <h3>Llamanos</h3>
              <p>+1 210 992 2894</p>
            </div>
          </div>
        </div>
        <div className="row" data-aos="fade-up">
          <div className="col-lg-6 ">
            <iframe
              className="mb-4 mb-lg-0"
              src="https://www.google.com/maps?q=6515%20Tezel%20Rd.%20San%20Antonio%20Tx%2078250&output=embed"
              frameBorder={0}
              style={{ border: 0, width: "100%", height: 384 }}
              allowFullScreen
            />
          </div>
          <div className="col-lg-6">
            <form role="form" onSubmit={submitForm} className="php-email-form">
              <div className="form-row">
                <div className="col-md-6 form-group">
                  <input
                    type="text"
                    name="name"
                    value={form.name}
                    onChange={handleInputField}
                    className="form-control"
                    id="name"
                    placeholder="Nombre"
                    data-rule="minlen:4"
                    data-msg="Por favor por lo menos 4 letras"
                  />
                  <div className="validate" />
                </div>
                <div className="col-md-6 form-group">
                  <input
                    type="email"
                    value={form.email}
                    onChange={handleInputField}
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Correo Electronico"
                    data-rule="email"
                    data-msg="Por favor entre un correo valido"
                  />
                  <div className="validate" />
                </div>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  value={form.subject}
                  onChange={handleInputField}
                  className="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Asunto"
                  data-rule="minlen:4"
                  data-msg="Por favor por lo menos 8 letras"
                />
                <div className="validate" />
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  value={form.message}
                  onChange={handleInputField}
                  name="message"
                  rows={5}
                  data-rule="required"
                  data-msg="Por fovor escribanos su mensaje"
                  placeholder="Mensaje"
                  defaultValue={""}
                />
                <div className="validate" />
              </div>
              <div className="mb-3">
                {formState.loading ? (
                  <div className="loading">Loading</div>
                ) : null}
                {formState.hassError ? (
                  <div className="error-message">
                    En este momento no pudemos enviar su mensage. Trate mas
                    tarde.
                  </div>
                ) : null}
                {formState.messageSent ? (
                  <div className="sent-message">
                    Su mensaje ha sido enviado. Gracias!
                  </div>
                ) : null}
              </div>
              <div className="text-center">
                <button type="submit">Send Message</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    //  <!-- End Contact Section -->
  );
};

export default ContactForm;
