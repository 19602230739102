import React, {useEffect} from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'

import Logo from "../../src/assets/img/logo.svg";

import video from '../assets/crop3.mp4';


const BackgroundVideo = () => {

    useEffect(() => {
        let videoElment = document.querySelector('video');
        videoElment.playbackRate = 0.75;
       // videoElment.play();
    },[]);

    return (


        <section id="hero" className="d-flex align-items-center content" >
            <video autoPlay="autoplay" playsInline="playsinline" loop="loop" muted="muted" className="" >
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="container align-items-center" data-aos="fade-up" data-aos-delay={500}>
                <h1>Bienvenidos A Casa</h1>
                {/* <h2> <span>Iglesia</span> La Mies</h2> */}
                <div>
                    <img
                        src={Logo}
                        className="d-inline-block align-top"
                        alt="La Mies Logo"
                    />
                </div>

                <AnchorLink offset='30' className="btn-get-started scrollt" href="#conocenos"> conocenos</AnchorLink>
            </div>
        </section >
       
       
    )
}

export default BackgroundVideo